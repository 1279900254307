<template>

  <div v-if="impressionsCount !== false" class="row">
    <div class="flex xs12 md6">
      <vac-card>
        <p :style="{color: this.$themes.primary}" class="display-2 mb-1">
          {{ (impressionsCount).toLocaleString("ru") }}</p>
        <p class="no-wrap">{{ $t('Impressions') }}</p>
      </vac-card>
    </div>

    <div class="flex xs12 md6">
      <vac-card>
        <div class="row row-separated">
          <div v-if="usersCount !== false" class="flex xs5">
            <p :style="{color: this.$themes.primary}" class="display-2 mb-1 text--center">
              {{ (usersCount).toLocaleString("ru") }}</p>
            <p class="text--center mb-1">{{ $t('Users') }}</p>
          </div>
          <div v-if="productsCount !== false" class="flex xs5">
            <p :style="{color: this.$themes.info}" class="display-2 mb-1 text--center">
              {{ (productsCount).toLocaleString("ru") }}</p>
            <p class="text--center no-wrap mb-1">{{ $t('Product cards') }}</p>
          </div>
          <!--<div class="flex xs4">-->
          <!--<p class="display-2 mb-1 text&#45;&#45;center" :style="{color: this.$themes.warning}">91</p>-->
          <!--<p class="text&#45;&#45;center mb-1">{{$t('Coverage')}}</p>-->
          <!--</div>-->
        </div>
      </vac-card>
    </div>
  </div>

</template>

<script>

export default {
  name: 'Stats',
  props: {
    data: {
      type: Object,
      default: () => {
      },
    },
    impressionsCount: {
      type: [Number, Boolean],
      default: 0,
    },
    productsCount: {
      type: [Number, Boolean],
      default: 0,
    },
    usersCount: {
      type: [Number, Boolean],
      default: 0,
    },
  },
};
</script>

<style lang="scss">
@media print {
  .vac-card {
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #404a53;

    div {
      div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
      }
    }
  }
}
</style>
